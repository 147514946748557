import React from "react"
import i18n from "../i18n"
import { I18nextProvider } from 'react-i18next';

const Layout = ({ children }) => {
  return (
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      {children}
    </I18nextProvider>
  )
}

export default Layout