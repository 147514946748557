exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asiakkaaksi-js": () => import("./../../../src/pages/asiakkaaksi.js" /* webpackChunkName: "component---src-pages-asiakkaaksi-js" */),
  "component---src-pages-blogi-js": () => import("./../../../src/pages/blogi.js" /* webpackChunkName: "component---src-pages-blogi-js" */),
  "component---src-pages-datahanke-js": () => import("./../../../src/pages/datahanke.js" /* webpackChunkName: "component---src-pages-datahanke-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-icare-js": () => import("./../../../src/pages/icare.js" /* webpackChunkName: "component---src-pages-icare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kauppa-js": () => import("./../../../src/pages/kauppa.js" /* webpackChunkName: "component---src-pages-kauppa-js" */),
  "component---src-pages-meta-js": () => import("./../../../src/pages/meta.js" /* webpackChunkName: "component---src-pages-meta-js" */),
  "component---src-pages-myynti-js": () => import("./../../../src/pages/myynti.js" /* webpackChunkName: "component---src-pages-myynti-js" */),
  "component---src-pages-omistajaksi-js": () => import("./../../../src/pages/omistajaksi.js" /* webpackChunkName: "component---src-pages-omistajaksi-js" */),
  "component---src-pages-osaaminen-js": () => import("./../../../src/pages/osaaminen.js" /* webpackChunkName: "component---src-pages-osaaminen-js" */),
  "component---src-pages-saavutettavuus-js": () => import("./../../../src/pages/saavutettavuus.js" /* webpackChunkName: "component---src-pages-saavutettavuus-js" */),
  "component---src-pages-teollisuus-js": () => import("./../../../src/pages/teollisuus.js" /* webpackChunkName: "component---src-pages-teollisuus-js" */),
  "component---src-pages-toimintatapa-js": () => import("./../../../src/pages/toimintatapa.js" /* webpackChunkName: "component---src-pages-toimintatapa-js" */),
  "component---src-pages-tyomme-js": () => import("./../../../src/pages/tyomme.js" /* webpackChunkName: "component---src-pages-tyomme-js" */),
  "component---src-pages-yhteys-js": () => import("./../../../src/pages/yhteys.js" /* webpackChunkName: "component---src-pages-yhteys-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

